import React, { useState } from 'react';
import './App.css';
import { TbReport } from 'react-icons/tb';
import 'primereact/resources/primereact.min.css';
import Report from './Report'; // Import the Report component
import Dash from './Dash';

function App() {
  const [showReport, setShowReport] = useState(false); // State to manage whether to show Report



  const handleLoadReport = () => {
    setShowReport(true); // Set showReport state to true to render the Report component
  };


 

  return (
    <div className="App">
      <div className='par1'>
        { !showReport && <Dash/> } {/* Render SearchTool only if showReport is false */}
      </div>
      { showReport ? ( // Conditional rendering based on showReport state
        <Report />
      ) : (
        <div className="par">
         
          <div className="fire-icon" onClick={handleLoadReport}>
            <TbReport className='icon1' /> <h6>Report</h6>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
