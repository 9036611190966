import React, { useState } from 'react';
import { Button, ButtonContent, Icon } from 'semantic-ui-react';
import SearchModal from './SearchModal';

const Dash = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className='dash'>
      <Button animated onClick={handleShow}>
        <ButtonContent visible>Open Search Tool</ButtonContent>
        <ButtonContent hidden>
          <Icon name='search' />
        </ButtonContent>
      </Button>

      <SearchModal show={showModal} handleClose={handleClose} />
    </div>
  );
};

export default Dash;
