import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TbReport } from 'react-icons/tb';
import SearchTool from './SearchTool';
import Report from './Report';

// eslint-disable-next-line react/prop-types
const SearchModal = ({ show, handleClose }) => {
  const [showReport, setShowReport] = useState(false);

  const handleLoadReport = () => {
    setShowReport(true);
  };

  const handleBack = () => {
    setShowReport(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Search Tool</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showReport ? (
          <>
            <div className="fire-icon" onClick={handleLoadReport}>
              <TbReport className='icon1' />  <h6>Report</h6>
            </div>
            <SearchTool />
          </>
        ) : (
          <Report onBack={handleBack} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchModal;
