/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { SiGoogletranslate } from 'react-icons/si';
import { PulseLoader } from 'react-spinners';
import stringSimilarity from 'string-similarity';
import { FaFireFlameCurved } from 'react-icons/fa6';

const SearchTool = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [googleResults, setGoogleResults] = useState([]);
  const [youtubeResults, setYoutubeResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [matchingPercentages, setMatchingPercentages] = useState({});
  const [remarks, setRemarks] = useState({});
  const [matchingyPercentages, setMatchingyPercentages] = useState({});
  const [googleRemarks, setGoogleRemarks] = useState({});
   
      
  const handleSearch = () => {
    // Check if title and description are already saved
  
    continueSearch();
         
  };

  const continueSearch = () => {
    const query = encodeURIComponent(`${title} ${description}`).replace(/%20/g, '+');
    if (query === '') {
      alert('Please enter project title and description.');
      return;
    }

    setLoading(true);

    setGoogleResults([]);
    setYoutubeResults([]);
    setSearchPerformed(true);

       
        
    fetch('https://projectverifier.helloindiasolutions.com/server.php',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
                   
        },
        body: JSON.stringify({ links: selectedItems.map((item) => item.link) }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      })
      .catch((error) => {
        console.error('Error saving links:', error);
      });

    // Check if both title and description are empty
    if (title.trim() === '' && description.trim() === '') {
      setLoading(false);
      return;
    }

    fetch(`https://www.googleapis.com/customsearch/v1?key=AIzaSyBOrZt5X7S6tEuKgnF-TEeoDuJrEFboETU&cx=d1d61ed55479845f5&q&q=${query}&num=5`)
      .then(response => response.json())
      .then(data => {
        const items = data.items || [];
        const matchingPercentages = {};

        items.forEach((item, index) => {
          const similarity = stringSimilarity.compareTwoStrings(query, item.title + item.snippet);
          matchingPercentages[`google${index}`] = similarity;
        });

        setGoogleResults(items);
        setMatchingPercentages(matchingPercentages);
      })
      .catch(error => console.error('Error fetching Google search data:', error));

    fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyBOrZt5X7S6tEuKgnF-TEeoDuJrEFboETU&q=${query}&part=snippet&type=video&maxResults=5`)
      .then(response => response.json())
      .then(data => {
        const items = data.items || [];
        const matchingyPercentages = {};

        items.forEach((item, index) => {
          const similarity = stringSimilarity.compareTwoStrings(query, item.snippet.title);
          matchingyPercentages[`youtube${index}`] = similarity;
        });

        setYoutubeResults(items);
        setMatchingyPercentages(matchingyPercentages);
      })
      .catch(error => console.error('Error fetching YouTube data:', error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (event, item, index) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedItems(prevItems => [...prevItems, item]);
    } else {
      setSelectedItems(prevItems => prevItems.filter(selectedItem => selectedItem !== item));
      // Remove corresponding remarks from state when unchecking
      const updatedGoogleRemarks = { ...googleRemarks };
      delete updatedGoogleRemarks[item.link];
      setGoogleRemarks(updatedGoogleRemarks);

      const updatedRemarks = { ...remarks };
      delete updatedRemarks[`youtube${index}`];
      setRemarks(updatedRemarks);
    }
  };

  const handleSave = () => {
    const selectedGoogleLinks = googleResults
      .filter((item) => selectedItems.includes(item))
      .map((item) => ({
        link: item.link,
        matchingPercentage: (matchingPercentages[`google${googleResults.indexOf(item)}`] || 0) * 100,
        title: title,
        description: description,
        remarks: googleRemarks[item.link] || ''
      }));

    const selectedYoutubeLinks = youtubeResults
      .filter((item) => selectedItems.includes(item))
      .map((item) => {
        const index = youtubeResults.indexOf(item); // Find index of selected item
        return {
          link: `https://www.youtube.com/watch?v=${item.id.videoId}`,
          matchingyPercentage: (matchingyPercentages[`youtube${index}`] || 0) * 100,
          title: title,
          description: description,
          remarks: remarks[`youtube${index}`] || ''
        };
      });

    const selectedLinks = [...selectedGoogleLinks, ...selectedYoutubeLinks];

    fetch('https://projectverifier.helloindiasolutions.com/server.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        links: selectedLinks
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        alert(data.message);
        window.location.reload(); // Reload the page after saving
      })
      .catch((error) => {
        console.error('Error saving links:', error);
        alert('Error saving links:', error.message);
        window.location.reload(); // Reload the page after saving
      });
  };


  const allglink = () => {
    // Check Google Results matching percentages
    const googlePercentages = Object.values(matchingPercentages);
    let sum = 0;
    for (let i = 0; i < googlePercentages.length; i++) {
      sum += googlePercentages[i];
    }
    const googlePercentageValue = sum * 100;

    return googlePercentageValue > 20;
  };

  const allylink = () => {
    // Check YouTube Results matching percentages
    const youtubePercentages = Object.values(matchingyPercentages);
    let sum = 0;
    for (let j = 0; j < youtubePercentages.length; j++) {
      sum += youtubePercentages[j];
    }
    const youtubePercentageValue = sum * 100;

    return youtubePercentageValue > 20;
  };

  // Example usage




  const handleLoadPlagiarismDetector = () => {
    window.open('https://plagiarismdetector.net/', '_blank');
  };

  const handleLoadtrans = () => {
    window.open('https://translate.google.com/?hl=ta&sl=ta&tl=en&op=translate/', '_blank');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="left-section col-sm-12 col-lg-7 col-md-12" style={{ position: 'sticky', top: 0, maxHeight: '100vh', overflowY: 'auto' }}>
          <h3>Our mission is to fulfill everyone's NEEDS in Content detection...</h3>
          <div className="search-bar">
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter project title..." style={{ fontSize: '14px' }} />
            {title && <MdClear className="clear-icon" onClick={() => setTitle('')} />}
          </div>
          <div className="search-bar">
            <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={350} placeholder="Enter project description..." style={{ fontSize: '14px', height: '100px', top: '-80px' }} />
            {description && <MdClear className="clear-icon" onClick={() => setDescription('')} />}
          </div>

          <button onClick={handleSearch}>Search</button>
          {selectedItems.length > 0 && <button className='savebtn' onClick={handleSave}>Save</button>}
          <div className='butfire'>
            <div className="fire-icon1" onClick={handleLoadPlagiarismDetector}>
              <h5> <FaFireFlameCurved className='icon1' />
                                Try on Other..</h5>
            </div>
            <div className="fire-icon2" onClick={handleLoadtrans}>
              <h5> <SiGoogletranslate className="google-translate-icon" onClick={handleLoadtrans} />
                                Translate</h5>
            </div>
          </div>
        </div>
        <div className="right-section col-sm-12 col-lg-6 col-md-12" style={{ flex: 1, maxHeight: '100vh', overflowY: 'auto' }}>
          <div className="container">
            <div className="row">
              {loading ? (
                <div className="spinner-container">
                  <PulseLoader color="#36d7b7" size={14} cssOverride={{ display: 'block', 'margin-left': '240px', 'margin-right': 'auto' }} loading={loading} />
                </div>
              ) : searchPerformed && googleResults.length === 0 && youtubeResults.length === 0 ? (
                <p className="errormsg">Sorry buddy This Project not found</p>
              ) : (
                <>
                  <div className="col-lg-7 col-sm-12">
                    <h4 style={{ border: allglink() ? '3px solid red' : '3px solid green', boxShadow: allglink() ? '0px 0px 10px 0px rgba(255,0,0,0.75)' : 'none', fontSize: '15px'}}>Google Results</h4>
                    <div className="search-section">
                      <div id="googleResults" className="search-results">
                        {googleResults
                          .map((item, index) => ({ item, index }))
                          .sort((a, b) => matchingPercentages[`google${b.index}`] - matchingPercentages[`google${a.index}`])
                          .map(({ item, index }) => (
                            <div key={index} className="result-item">
                              <div className="result-content">
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                  <h3>{item.title}</h3>
                                  <p>{item.snippet}</p>
                                </a>
                                <div className='pbar' style={{ boxShadow: selectedItems.includes(item) ? 'rgba(71, 255, 15, 0.2) 0px 7px 29px 0px' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                  <p>Checked <input type="checkbox" className="result-checkbox" onChange={(event) => handleCheckboxChange(event, item)} /></p>
                                  <p>Matching Percentage
                                    <input
                                      type="number"
                                      value={(matchingPercentages[`google${index}`] * 100).toFixed(0)}
                                      onChange={(e) => {
                                        const newPercentage = parseFloat(e.target.value) / 100;
                                        const updatedMatchingPercentages = { ...matchingPercentages };
                                        updatedMatchingPercentages[`google${index}`] = newPercentage;
                                        setMatchingPercentages(updatedMatchingPercentages);
                                      }}
                                      style={{ width: '20px' }}
                                    />%
                                  </p>
                                  <p className='rem'>Remarks
                                    <input
                                      type="text"
                                      placeholder="Add remarks..."
                                      value={googleRemarks[item.link] || ''} // Set value from state
                                      onChange={(e) => {
                                        const updatedRemarks = { ...googleRemarks };
                                        updatedRemarks[item.link] = e.target.value; // Update state with new remark
                                        setGoogleRemarks(updatedRemarks);
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-12">
                    <h4 style={{ border: allylink() ? '3px solid red' : '3px solid green', boxShadow: allylink() ? '0px 0px 10px 0px rgba(255,0,0,0.75)' : 'none', fontSize: '15px' }}>Youtube Results</h4>
                    <div className="search-section">
                      <div id="youtubeResults" className="search-results">
                        {youtubeResults
                          .map((item, index) => ({ item, index }))
                          .sort((a, b) => matchingyPercentages[`youtube${b.index}`] - matchingyPercentages[`youtube${a.index}`])
                          .map(({ item, index }) => (
                            <div key={index}>
                              <a href={`https://www.youtube.com/watch?v=${item.id.videoId}`} target="_blank" rel="noopener noreferrer">
                                <img src={item.snippet.thumbnails.default.url} alt={item.snippet.title} />
                              </a>
                              <div className='pbar' style={{ boxShadow: selectedItems.includes(item) ? 'rgba(71, 255, 15, 0.2) 0px 7px 29px 0px' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                <p className='che'>Checked <input type="checkbox" onChange={(event) => handleCheckboxChange(event, item, index)} /></p>
                                <p>Matching Percentage
                                  <input
                                    type="number"
                                    value={(matchingyPercentages[`youtube${index}`] * 100).toFixed(0)}
                                    onChange={(e) => {
                                      const newPercentage = parseFloat(e.target.value) / 100;
                                      const updatedMatchingPercentages = { ...matchingyPercentages };
                                      updatedMatchingPercentages[`youtube${index}`] = newPercentage;
                                      setMatchingyPercentages(updatedMatchingPercentages);
                                    }}
                                    style={{ width: '20px' }}
                                  />%
                                </p>
                                <p className='rem'>Remarks
                                  <input
                                    type="text"
                                    placeholder="Add remarks..."
                                    value={remarks[`youtube${index}`] || ''} // Set value from state
                                    onChange={(e) => {
                                      const updatedRemarks = { ...remarks };
                                      updatedRemarks[`youtube${index}`] = e.target.value; // Update state with new remark
                                      setRemarks(updatedRemarks);
                                    }}
                                  />
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default SearchTool;
