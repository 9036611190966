import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DataTable from 'react-data-table-component';
import { Card, CardContent, Typography, Button } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Google';

// eslint-disable-next-line react/prop-types
const Report = ({ onBack }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalDuplicateCount, setTotalDuplicateCount] = useState(0);

  const fetchProjects = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://projectverifier.helloindiasolutions.com/server.php?page=${page}&per_page=${perPage}`);
      const { data, total } = response.data;
      setData(data);
      setTotalRows(total);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [perPage]);

  const fetchTotalDuplicateCount = useCallback(async () => {
    try {
      const response = await axios.get('https://projectverifier.helloindiasolutions.com/server.php?total_duplicates=true');
      setTotalDuplicateCount(response.data.duplicates);
    } catch (error) {
      console.error('Error fetching total duplicate count:', error);
    }
  }, []);

  const handlePageChange = (page) => {
    fetchProjects(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://projectverifier.helloindiasolutions.com/server.php?page=${page}&per_page=${newPerPage}`);
      const { data } = response.data;
      setData(data);
      setPerPage(newPerPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects(1);
    fetchTotalDuplicateCount();
  }, [fetchProjects, fetchTotalDuplicateCount]);

  const columns = [
    { name: 'Date of Entry', selector: (row) => row.doe, sortable: true, sortFunction: (a, b) => new Date(b) - new Date(a) },
    { name: 'Title', selector: (row) => row.title, sortable: true },
    { name: 'Description', selector: (row) => row.decrip, sortable: true },
    { 
      name: 'Link', 
      selector: (row) => row.link, 
      sortable: true,
      cell: (row) => <a href={row.link} target="_blank" rel="noopener noreferrer">{row.link}</a>
    },
    { 
      name: 'Category', 
      selector: (row) => row.link.includes('youtube') ? 'YouTube' : 'Google', 
      cell: (row) => row.link.includes('youtube') ? <YouTubeIcon /> : <GoogleIcon /> 
    },
    { name: 'Percentage', selector: (row) => row.percent, sortable: true },
    { name: 'Remarks', selector: (row) => row.remarks, sortable: true },
  ];

  const downloadPDF = async () => {
    try {
      const response = await axios.get('https://projectverifier.helloindiasolutions.com/server.php?per_page=all');
      const allData = response.data;

      const doc = new jsPDF('landscape');
      doc.text('Projects Report', 14, 16);

      const tableColumn = ['Date of Entry', 'Title', 'Description', 'Link', 'Category', 'Percentage', 'Remarks'];
      const tableRows = [];

      allData.forEach((project) => {
        const projectData = [
          project.doe,
          project.title,
          project.decrip,
          project.link,
          project.link.includes('youtube') ? 'YouTube' : 'Google',
          project.percent,
          project.remarks
        ];
        tableRows.push(projectData);
      });

      doc.autoTable({
        startY: 20,
        head: [tableColumn],
        body: tableRows,
        styles: { fontSize: 8 },
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 35 },
          2: { cellWidth: 45 },
          3: { cellWidth: 45 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 30 }
        },
        theme: 'striped',
        margin: { top: 20 }
      });

      doc.save('projects_report.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div>
      <Button onClick={onBack} variant="contained" color="secondary" style={{ marginBottom: '20px', width: '200px', marginLeft: '20px' }}>
        Back
      </Button>
      <Button onClick={downloadPDF} variant="contained" color="primary" style={{ marginBottom: '20px', width: '200px', marginLeft: '20px' }}>
        Download PDF
      </Button>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        <Card style={{ minWidth: '350px' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Total Projects  <DoneAllIcon />
            </Typography>
            <Typography variant="h6" component="div">
              {totalRows}
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ minWidth: '350px' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Duplicate Projects <FileCopySharpIcon/>
            </Typography>
            <Typography variant="h6" component="div">
              {totalDuplicateCount}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default Report;
